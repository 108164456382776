/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '~@fortawesome/fontawesome-pro/css/all.css';

@import '~loaders.css/loaders.css';

@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/bootstrap.scss';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';

//== Bootstrap
@import './app/shared/styles/bootstrap.scss';
//== Application
@import './app/shared/styles/app.scss';

// Import module styling
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';
@import './app/shared/styles/app/variables.scss';

@import '../node_modules/flag-icon-css/css/flag-icon.min.css';

@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

// Toastr
@import '../node_modules/ngx-toastr/toastr';

//== CUSTOM SQUARE STYLES

.warning-label {
  color: $danger !important;
  font-weight: bold;
}
button {
  background-color: transparent;
  border: none;
}

/* used mainly to style the printable version of the plannings (location & teacher) */
.custom-flex-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* for options that are already selected and need to be disabled */
option.custom-option-disabled:disabled {
  color: $danger-light;
}

.custom-margin-top {
  margin-top: 15px !important;
}

.custom-margin-bottom {
  margin-bottom: 15px !important;
}

.custom-margin-left {
  margin-left: 15px !important;
}

.custom-margin-right {
  margin-right: 15px !important;
}

.custom-no-margin {
  margin: 0 !important;
}

.custom-no-margin-left {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.page-header {
  margin-top: 0 !important;
}

/* Fix header text placement */
.bs-datepicker-head > bs-datepicker-navigation-view {
  display: flex;
  justify-content: space-around;
}

/* no margin on card title */
.page-header {
  margin-top: 0 !important;
}

/* Removes the padding of an element */
.custom-no-padding {
  padding: 0 !important;
}

/* button with transparent bg */
button.custom-transparent {
  background-color: transparent;
  border: none;
}

app-static-typeahead {
  width: 100%;
}

.custom-timepicker td {
  border: none !important;
  padding: 0;
}

.custom-timepicker td.ng-star-inserted {
  vertical-align: middle;
}

.custom-max-width {
  width: 100%;
}

.unspecified {
  font-style: italic;
}

/* For elements that need to be display only in print mode */
.print-only-block,
.print-only-block-inline,
.print-only-flex,
.print-only-flexbox {
  display: none;
}

/* For elements that need to be hidden from the web page but displayed in print monde */
.hidden-in-web {
  display: none;
}

/* For teacher planning and location planning where some cells don't have borders */
td.custom-no-border {
  border: none !important;
}

.teacher-planning-signature > label {
  display: block;
  margin-bottom: 3%;
}

.teacher-planning-signature > label:first-child {
  margin-top: 3%;
}

//== CUSTOM TINYMCE EDITOR STYLES

/* Removes status footer of tinyMCE editor */
.tox-statusbar {
  display: none !important;
}

//== ANGLE CSS RULES

/* Custom Theme */
.theme-angle {
  .bs-datepicker-head {
    background-color: $info;
  }
  .bs-datepicker-body table td {
    span.selected,
    &.selected span,
    span[class*='select-']:after,
    &[class*='select-'] span:after {
      background-color: $info;
    }
    &.week span {
      color: $info;
    }
  }
}

label.required {
  &::after {
    content: '*';
    color: red;
    padding-left: 3px;
  }
}

/* Fix for datepicker in modal window */
bs-datepicker-container {
  z-index: 3000 !important;
}

/* NGX-DATATABLE CSS */

// do not display arrows that filters the list
.ngx-datatable .sort-btn {
  display: none !important;
}

.ngx-datatable.bootstrap {
  background-color: transparent;
  box-shadow: 0 0 0 #000;
}

.ngx-datatable.scroll-vertical {
  height: 300px;
}

// workaround for use of detail rows
// with other table in the same page
.no-detail-row .datatable-row-detail {
  display: none !important;
}

// change color of active cells selected
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.single-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active,
.ngx-datatable.bootstrap.multi-click-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.bootstrap.single-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.single-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.bootstrap.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.bootstrap.multi-click-selection
  .datatable-body-row.active:hover
  .datatable-row-group {
  background-color: $primary !important;
  .datatable-body-cell {
    color: #fff;
  }
}
// remove link decoration
.datatable-icon-right {
  text-decoration: none !important;
}
// align table with no data legend
.ngx-datatable.bootstrap .empty-row {
  text-align: center;
  margin-top: 20px;
}
// tabele footer
.ngx-datatable.bootstrap .datatable-footer {
  background-color: transparent;
  color: $body-color;
}
// table pagination

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: $pagination-color;
}
.pager li > a,
.pager li > span {
  border-color: $pagination-border-color;
}
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled).active
  a,
.ngx-datatable.bootstrap
  .datatable-footer
  .datatable-pager
  ul
  li:not(.disabled):hover
  a {
  background-color: $primary;
  border-color: $primary;
  color: #fff;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  border: 0;
}

.ngx-datatable.bootstrap
  .datatable-body
  .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.025);
}

/* little trick to change the arrow DOWN (\66) direction when a row is expanded */
datatable-body-cell[ng-reflect-expanded='true'] > div > a:before {
  content: '\66';
}

/*
* Fix ngx-datatable header height because of header customization
*/
.datatable-header-cell-template-wrap {
  height: auto !important;
  font-weight: bold;
}

/* custom colors for rows and row details (when expanded) */
.datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.035) !important;
}
.datatable-body-row.datatable-row-even + .datatable-row-detail {
  background-color: rgba(0, 0, 0, 0.035) !important;
}

/*
Fixes buggy V-scrollbar when row height set to 100% (for row-details)

Thanks for the fix :
https://github.com/swimlane/ngx-datatable/issues/1269
*/
.datatable-scroll {
  width: 100% !important;
}

/* align header text to center for the wanted columns */
.datatable-header-custom-center-align {
  text-align: center !important;
}
