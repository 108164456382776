// Summernote

.summernote {
    + .note-editor {
        background-image: none !important;
        margin-top: 0;
        &.note-frame {
            border-color: $gray-light;
            &.fullscreen {
                margin-top: 0;
                @include media-breakpoint-up(md) {
                    top: $navbar-hg;
                    left: $aside-wd;
                    right: 0;
                    width: auto !important;
                }
            }
        }
    }
}
// Summernote fullscreen layout support
.aside-collapsed  {
    .summernote + .note-editor.note-frame.fullscreen {
        @include media-breakpoint-up(md) {
            left: $aside-wd-collapsed;
        }
    }
}

.aside-collapsed-text  {
    .summernote + .note-editor.note-frame.fullscreen {
        @include media-breakpoint-up(md) {
            left: $aside-wd-collapsed-text;
        }
    }
}

.aside-float  {
    .summernote + .note-editor.note-frame.fullscreen {
        @include media-breakpoint-up(md) {
            margin-left: 30px;
        }
    }
}

// BS4 suppor for summernote
// https://github.com/summernote/summernote/issues/1324
.panel-heading {
    @extend .card-header;
}

.note-btn-group {
    .btn-default {
        @extend .btn-secondary;
    }

    .dropdown-menu {
        > li {
            > a {
                display: block;
                padding: 3px 20px;
                clear: both;
                font-weight: normal;
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
            }
        }
    }
}

.note-popover .popover-content .note-color .dropdown-toggle,
.note-editor .panel-heading.note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 1px;
}

.note-editable {
    margin-top: 10px;
}
