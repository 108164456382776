/* ========================================================================
   Component: file-upload
 ========================================================================== */

.file-upload {
    display: block;
    overflow: hidden;
    position: relative;

    [type=file] {
        cursor: pointer;
        display: block;
        filter: alpha(opacity=0);
        min-height: 100%;
        min-width: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
    }
}
